import React, { Component } from "react"
import getSpecificCookie from "../util/cookie"

// This is the main function that allows contexts to work and is built in to react.
// AppContext will have AppContext.Provider, which is used here and will also have
// AppContext.Consumer, which allows the global state to be used throughout the app.
// Hence why AppContext is exported as well as the class component below.

const defaultState = {
  isExitRampOpen: false,
  handleIsExitRampOpen: () => {},
  externalUrl: "",
  currentPage: "",
  handleCurrentPage: () => {},
  activeHeaderDropdown: "",
  handleActiveHeaderDropdown: () => {},
  activeDropdownLink: "",
  handleActiveDropdownLink: () => {},
  tabLink: "",
  tabBool: "",
  handleTabLink: () => {},
  siteVisited: "",
  handleSiteVisited: () => {},
  isCookieVisible: false,
  handleIsCookieVisible: () => {},
}

export const AppContext = React.createContext(defaultState)

class AppProvider extends Component {
  constructor(props) {
    super(props)
    // With context, this.state will have the state that needs to be shared amongst multiple
    // Components in the app, but it is important to note that this.state also contains
    // The functions that are responsible for changing the state.  In order to maintain readability,
    // I have seen that many engineers indent the function, directly below the state or states that
    // That particular function is responsible for updating.
    this.state = {
      isExitRampOpen: false,
      handleIsExitRampOpen: url => {
        this.setState({
          isExitRampOpen: !this.state.isExitRampOpen,
          externalUrl: url,
        })
      },
      externalUrl: "",
      currentPage: "",
      handleCurrentPage: val => {
        this.setState({
          currentPage: val,
        })
      },
      activeHeaderDropdown: "",
      handleActiveHeaderDropdown: val => {
        this.setState({
          activeHeaderDropdown: val,
        })
      },
      activeDropdownLink: "",
      handleActiveDropdownLink: val => {
        this.setState({
          activeDropdownLink: val,
        })
      },
      tabLink: "",
      tabBool: "",
      handleTabLink: (val, bool) => {
        this.setState({
          tabLink: val,
          tabBool: bool,
        })
      },
      siteVisited: false,
      handleSiteVisited: () => {
        this.setState({
          siteVisited: true,
        })
      },
      isCookieVisible: getSpecificCookie("accept") !== "accept",
      handleIsCookieVisible: val => {
        if (val) {
          const date = new Date(Date.now() + 1000 * 60 * 60 * 24 * 14)
          document.cookie = `accept=accept; expires=${date.toUTCString()}`
        }
        this.setState({
          isCookieVisible: false,
        })
      },
    }
  }

  render() {
    // AppContext.Provider is a built in function for context, it is important that
    // this.props.children is used and to note that this.state is passed in.

    // The class AppProvider will then wrap the outermost component(s) in the app,
    // In our case at the moment, App.jsx
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

export default AppProvider
