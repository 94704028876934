// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-commercial-insurance-jsx": () => import("../src/pages/commercial-insurance.jsx" /* webpackChunkName: "component---src-pages-commercial-insurance-jsx" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-jsx": () => import("../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */)
}

